import axios from "axios";
import https from "https";
import { HttpMethod } from "./http-methods";

const httpsAgent = new https.Agent({
  rejectUnauthorized: false,
});

export const instanceAxios = axios.create({
  baseURL: "https://marketexchangers.com/api/v1",
  httpsAgent,
  withCredentials: false,
});

export const instanceAxiosGoApi = axios.create({
  baseURL: "https://marketexchangers.com/api/v2",
  // baseURL: "http://localhost:4000",
  httpsAgent,
  withCredentials: false,
});

export class APIService {
  static async get<Response, Request = void>(
    url: string,
    request?: Request
  ): Promise<Response> {
    const response = await instanceAxios({
      method: HttpMethod.GET,
      url,
      params: request,
    });

    return response.data;
  }

  static async post<Response, Body = void, Request = void>(
    url: string,
    body?: Body,
    request?: Request
  ): Promise<Response> {
    const response = await instanceAxios({
      method: HttpMethod.POST,
      url,
      params: request,
      data: body,
    });
    return response.data;
  }

  static delete(url: string) {
    return instanceAxios({
      method: HttpMethod.DELETE,
      url,
    });
  }

  static async put<Response, Body = void, Request = void>(
    url: string,
    body?: Body,
    request?: Request
  ): Promise<Response> {
    const response = await instanceAxios({
      method: HttpMethod.PUT,
      url,
      params: request,
      data: body,
    });
    return response.data;
  }
}
